import styled from 'styled-components';

const LegalSectionWrapper = styled.section`
    .bold {
        font-weight:bold;
    }

    h2 {
        color: ${props => props.theme.colors.primaryDark};
        font-size:2rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .section__particle{
        position: absolute;
        &.one{
            width: 190px;
            left: -5%;
            bottom: 0px;
        }
        &.two{
            width: 144px;
            top: 27%;
            right: -4%;
        }
    }

    @media only screen and (max-width: 912px) {
        padding: 50px 0 35px 0;
        .section__particle{
            &.one {
                bottom: -122px;
            }
        }
    }
    @media only screen and (max-width: 480px) {

    }
    
    
`

export default LegalSectionWrapper;