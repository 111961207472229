import React from 'react';

import Layout, {Container, ContentWithPaddingXl} from '@components/Layout';
import Header from '@components/Header';
import Legal from '@sections/Legal';
import CookieBanner from '@components/Cookies/CookieBanner';
import { SectionContainer } from '@components/common/Layout';
import { UseContentfulCookies } from '@hooks/UseContentfulCookies';

const CookiesPage = () => {

  const { nodes } = UseContentfulCookies();

  return (
  <Layout>
    <Header />
    <SectionContainer id="home">

        <Legal headingText={nodes[0].titleHeading} content={nodes[0].content?.json}/>
    </SectionContainer>    
    <CookieBanner/>
  </Layout>
  );
}

export default CookiesPage;
