import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { createMuiTheme, ThemeProvider as ThemeProviderUI, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ScrollingProvider } from 'react-scroll-section';
import config from 'react-reveal/globals';
import preset from '@rebass/preset';
import colors from '../../colors';
import Helmet from './Helmet';
import { GlobalStyle } from './app.style';
import tw from "twin.macro";

/*
const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before { 
    box-sizing: inherit;
    }

  body {
    box-sizing: border-box; 
    margin: 0;
    font-family: Cabin, 'Open Sans', sans-serif;
    overflow-x: hidden;
    width: 100vw;
  }
`;
*/

config({ ssrFadeout: true });

const loadScript = (src) => {
  const tag = document.createElement('script');
  tag.src = src;
  tag.defer = true;

  document.getElementsByTagName('body')[0].appendChild(tag);
};

const theme = {
  ...preset,
  colors,
  fonts: {
    // body: 'Cabin, Open Sans, sans-serif',
    heading: 'inherit',
    monospace: 'monospace',
  },
};

const themeUI = createMuiTheme(
  {
  palette:
      {
      common:{"black":"#000","white":"#fff"},
      background:{"paper":"#fff","default":"#fafafa"},
      primary:{
          light:'#3cb1b0',
          main:'#00676d',
          dark:'rgba(0, 104, 109, 1)',
      },
      info:{
          light:'rgba(246, 242, 192, 1)',
          main:'#e3c42e',
          dark:'#c51162'
      },
      secondary: {
          light: '#3cb1b0',
          main:'#3cb1b0',
          dark:'#3cb1b0'
      },
      error:{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},
      text:{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}}
);


const Layout = ({ children }) => {
  useEffect(() => {
    loadScript('https://use.fontawesome.com/fd58d214b9.js');
  }, []);

  return (
    <main>
      
      <ThemeProvider theme={theme}>
      <ThemeProviderUI theme={themeUI}>
        <GlobalStyle />
        <ScrollingProvider>
          <Helmet />
          {children}
        </ScrollingProvider>
        </ThemeProviderUI>
      </ThemeProvider>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;


export const Container = tw.div`relative`;
export const ContentWithPadding= tw.div`max-w-full mx-auto py-20 lg:py-24 w-full`;
export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const ContentWithPaddingLg= tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl= tw.div`max-w-screen-2xl mx-auto`;