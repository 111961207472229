import React from 'react';
import { Section } from 'react-scroll-section';
import Container from './Container';

const SectionContainer = ({ id, children}, props) => (
  <Section id={id} style={{ position: 'relative' }}>
      <Container {...props}>
          {children}
      </Container>
  </Section>
);

export default SectionContainer;
