import { useStaticQuery, graphql } from 'gatsby';

export const UseContentfulCookies = () => {
    const { allContentfulLegal } = useStaticQuery (
      graphql`
      query LegalCookieQuery {
        allContentfulLegal(limit: 1, filter: { titleHeading:{eq:"Política de cookies"}}) {
          nodes {
            titleHeading
            content {
              json
            }
            id
          }
        }
      }
      `
    );

    return allContentfulLegal;
}

