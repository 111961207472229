import React from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import {Container, ContentWithPaddingXl} from '@components/Layout';
import { SectionHeading } from '@components/styled/Headings';
import particle1 from '@assets/images/app/particle/14.png';
import particle2 from '@assets/images/app/particle/15.png';
import LegalSectionWrapper from './legal.style';
import TextRich from '@components/TextRich';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;


const Legal = ({ headingText, content}) => (
<Container>
    <LegalSectionWrapper>
        <img className="section__particle one" src={particle1} alt="img" />
        <img className="section__particle two" src={particle2} alt="img" />
        <ContentWithPaddingXl>
            <HeadingRow>
                <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>
                <TextRich json={content}/>
            </Text>
        </ContentWithPaddingXl>
    </LegalSectionWrapper>
</Container>
);

export default Legal;